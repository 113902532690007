<template>
  <div>
    <v-container fluid>
      <v-row class="mb-0" justify="center">
        <v-col
          cols="12"
          lg="7"
          xl="6"
        >
          <slot name="default"></slot>
        </v-col>
        <v-divider
          v-if="$vuetify.breakpoint.lgAndUp"
          vertical
        />
        <v-col
          cols="12"
          lg="3"
        >
          <slot name="sidebar"></slot>
          <template v-if="bullets">
            <h3
              id="expertise-list"
              class="text-h5 primary--text"
            >{{ bulletsHeading }}</h3>
            <v-list dense class="transparent">
              <template v-for="(i, idx) in bullets">
                <template v-if="title === 'Concrete Services'">
                  <v-hover
                    v-slot="{ hover }"
                    :key="i.key"
                  >
                    <v-list-item
                      :class="`cursor text-overline ${hover ? 'primary' : ''}`"
                      @click="`${$vuetify.goTo(`#${i.key}`)}`"
                    >
                      <span :class="hover ? 'white--text' :'grey--text text--lighten-2'">
                        {{ i.title }}
                      </span>
                    </v-list-item>
                  </v-hover>
                </template>
                <template v-else>
                  <v-list-item
                    :key="i.key"
                    class="text-overline"
                  >
                    <span class="grey--text text--lighten-2">
                      {{ i.title }}
                    </span>
                  </v-list-item>
                </template>
                <v-divider
                  v-if="idx < bullets.length - 1"
                  :key="`${idx}-divider`"
                  class="grey darken-3"
                />
              </template>
            </v-list>
            <slot name="action"></slot>
          </template>
        </v-col>
      </v-row>
      <v-row
        v-if="images.length > 1"
        justify="center"
        class="my-12"
      >
        <v-col
          v-for="(i,idx) in images"
          :key="idx"
          class="cursor"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          @click="$vuetify.breakpoint.mdAndUp ? openImageDialog(i, idx) : ''"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                flat
                class="pa-0"
                color="grey darken-4"
              >
                <img
                  data-blink-ops="scale-crop: 600x400; scale-crop-position: smart_objects_points;"
                  :data-blink-uuid="i.uuid"
                  class="mx-auto rounded"
                  :alt="i.alt"
                  width="100%"
                  height="auto"
                />
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="secondary"
                    class="mb-1"
                  >
                    <div class="text-center pa-2 font-weight-bold">
                      {{ i.alt }}
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <component :is="content" />
    <ImageRowDialog
      v-if="$vuetify.breakpoint.mdAndUp && images.length > 0"
      :show="showImageDialog"
      :title="title"
      :initialImage="initialImage"
      :images="images"
      @close="showImageDialog = false"
    />
    <v-divider />
  </div>
</template>

<script>
export default {
  components: {
    ImageRowDialog: () => import(/* webpackChunkName: "concrete-services-image-dialog" */ '@/core/components/ImageRowDialog'),
    ConcreteServicesContent: () => import(/* webpackChunkName: "concrete-services" */ '../components/ConcreteServicesContent')
  },
  props: {
    title: {
      type: String,
      default: 'C&C Industrial, Inc.'
    },
    bulletsHeading: {
      type: String,
      default: 'Expertise'
    },
    bullets: {
      /** Array of { title } */
      type: Array,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    images: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      showImageDialog: false,
      imageDialogTitle: null,
      initialImage: 0
    }
  },
  methods: {
    openImageDialog (i, idx) {
      this.initialImage = idx
      this.activeImages = this.images.length > 0 ? this.images : i.images ? i.images : []
      this.imageDialogTitle = this.title ? this.title : i.title
      this.showImageDialog = true
    }
  }
}
</script>
